<template>
  <v-container>
    <v-row>
      <v-col>
        <h1 class="text-center">Briefing - Promotional Microsite</h1>
        <v-card>
          <v-card-title>
            {{ briefing[0].name }}
          </v-card-title>
          <v-card-subtitle>
            {{ briefing[0].company }}
          </v-card-subtitle>
          <v-card-text>
            Mobile: {{ briefing[0].phone }}
            <br>Email: {{ briefing[0].email }}
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <h2 class="mt-10 mb-5">About your Activity</h2>
    <v-row>
      <v-col
        cols="12"
      >
        <v-text-field
          v-model="brands"
          label="Brands(2)"
          outlined
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
        <v-text-field
          v-model="budget"
          label="Budget"
          outlined
          messages="Specify if it's an appropriate, max limit, or range"
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
        <v-text-field
          v-model="participating_venues"
          label="No. of Participating Venues"
          outlined
        />
      </v-col>
    </v-row>
    <h2 class="mt-10 mb-2">Dates &amp; Time</h2>
    <p class="mb-5">The dates that the microsite needs to be live and offline by may be different (earlier/later) than the promotional dates and time. Please allow time to have winners published on if this is needed.</p>
    <v-row>
      <v-col
        cols="6"
        md="3"
      >
        <v-dialog
          ref="dialog1"
          v-model="live_date_modal"
          :return-value.sync="live_date"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="live_date"
              label="Microsite Live by Date"
              append-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              outlined
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="live_date"
            scrollable
          >
            <v-spacer />
            <v-btn
              text
              color="primary"
              @click="live_date_modal = false"
            >
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.dialog1.save(live_date)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>
      </v-col>
      <v-col
        cols="6"
        md="3"
      >
        <v-text-field
          v-model="live_time"
          label="Microsite Live by Time"
          value="00:00:00"
          type="time"
          suffix="AEST"
          outlined
        />
      </v-col>
      <v-col
        cols="6"
        md="3"
      >
        <v-dialog
          ref="dialog2"
          v-model="end_date_modal"
          :return-value.sync="end_date"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="end_date"
              label="Microsite Offline by Date"
              append-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              outlined
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="end_date"
            scrollable
          >
            <v-spacer />
            <v-btn
              text
              color="primary"
              @click="end_date_modal = false"
            >
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.dialog2.save(end_date)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>
      </v-col>
      <v-col
        cols="6"
        md="3"
      >
        <v-text-field
          v-model="end_time"
          label="Microsite Offline by Time"
          value="00:00:00"
          type="time"
          suffix="AEST"
          outlined
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="6"
        md="3"
      >
        <v-dialog
          ref="dialog3"
          v-model="promo_start_date_modal"
          :return-value.sync="promo_start_date"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="promo_start_date"
              label="Promotion Start Date"
              append-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              outlined
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="promo_start_date"
            scrollable
          >
            <v-spacer />
            <v-btn
              text
              color="primary"
              @click="promo_start_date_modal = false"
            >
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.dialog3.save(promo_start_date)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>
      </v-col>
      <v-col
        cols="6"
        md="3"
      >
        <v-text-field
          v-model="promo_start_time"
          label="Promotion Start Time"
          value="00:00:00"
          type="time"
          suffix="AEST"
          outlined
        />
      </v-col>
      <v-col
        cols="6"
        md="3"
      >
        <v-dialog
          ref="dialog4"
          v-model="promo_end_date_modal"
          :return-value.sync="promo_end_date"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="promo_end_date"
              label="Promotion End Date"
              append-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              outlined
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="promo_end_date"
            scrollable
          >
            <v-spacer />
            <v-btn
              text
              color="primary"
              @click="promo_end_date_modal = false"
            >
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.dialog4.save(promo_end_date)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>
      </v-col>
      <v-col
        cols="6"
        md="3"
      >
        <v-text-field
          v-model="promo_end_time"
          label="Promotion End Time"
          value="00:00:00"
          type="time"
          suffix="AEST"
          outlined
        />
      </v-col>
    </v-row>
    <h2 class="mt-10">Details</h2>
    <v-text-field
      v-model="activity_name"
      label="Activity Name"
      outlined
    />
    <v-text-field
      v-model="activity_headline"
      label="Activity Headline"
      outlined
    />

    <v-select
      :items="url_type_itmes"
      label="Where do you want to run this promotion?"
      outlined
    />
    <v-row>
      <v-col
        cols="12"
        md="7"
      >
        <v-text-field
          v-model="url"
          label="URL (if applicable)"
          outlined
        />
      </v-col>
      <v-col
        cols="12"
        md="5"
      >
        <p class="mb-0">Please check one of the below</p>
        <v-radio-group>
          <v-radio
            label="I own/purchased this URL"
            value="own_url"
          />
          <v-radio
            label="I would like Pink Zebra to purhase on my behalf"
            value="purchase_url"
          />
        </v-radio-group>
      </v-col>
    </v-row>
    <v-select
      :items="urgency"
      label="Please specify the urgency of this brief"
      outlined
    />
    <h3 class="mt-5 mb-0">Promotional site banner creative</h3>
    <p>Please upload the banner here if you have one, or let us know the details if you need us to create one. The general specs are 1600px X 300px for web, 750px X 300px for mobile.</p>
    <v-row>
      <v-col
        cols="12"
        sm="6"
      >
        <v-textarea
          label="Tell us more ..."
          outlined
          rows="6"
          hide-details="auto"
        />
      </v-col>
      <v-col
        cols="12"
        sm="1"
        class="d-flex justify-center align-center"
      >
        <strong>OR</strong>
      </v-col>
      <v-col
        cols="12"
        sm="5"
      ><vue-dropzone id="dropzone" ref="myVueDropzone" :options="dropzoneOptions" :use-custom-slot="true">
        <div class="dropzone-custom-content">
          <h3 class="dropzone-custom-title">Drop file here or click to upload</h3>
          <div class="subtitle">.pdf/.jpg/.png files with a size less than 5mb</div>
        </div>
      </vue-dropzone>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        class="mb-0 pb-0"
      >
        <h3 class="mt-5 mb-0">Terms and Conditions</h3>
        <p class="mb-0">Please upload your activity's terms and conditions here.</p>
      </v-col>
      <v-col
        cols="12"
        md="7"
      >
        <vue-dropzone id="dropzone" ref="myVueDropzone" :options="dropzoneOptions" :use-custom-slot="true">
          <div class="dropzone-custom-content">
            <h3 class="dropzone-custom-title">Drop file here or click to upload</h3>
            <div class="subtitle">.doc/.pdf files with a size less than 5mb</div>
          </div>
        </vue-dropzone>
      </v-col>
      <v-col
        cols="12"
        md="5"
      >
        <p class="mb-0">Please check one of the below</p>
        <v-radio-group>
          <v-radio
            label="These are final with permits"
            value="with_permits"
          />
          <v-radio
            label="I'm still waiting on permits"
            value="without_permits"
          />
        </v-radio-group>
      </v-col>
    </v-row>
    <br>
    <h3 class="mt-5 mb-3">Privacy Policy</h3>
    <v-text-field
      v-model="privay_policy_url"
      label="Privacy Policy URL"
      outlined
    />
    <v-row>
      <v-col
        cols="12"
      >
        <h3 class="mt-5 mb-0">Frequently Asked Qeustions</h3>
        <p class="mb-3">Please upload the activity FAQs here, if any.</p>
        <vue-dropzone id="dropzone" ref="myVueDropzone" :options="dropzoneOptions" :use-custom-slot="true">
          <div class="dropzone-custom-content">
            <h3 class="dropzone-custom-title">Drop file here or click to upload</h3>
            <div class="subtitle">.doc/.pdf files with a size less than 5mb</div>
          </div>
        </vue-dropzone>
      </v-col>
    </v-row>
    <h3 class="mt-10 mb-0">Other/custom requirements</h3>
    <p>If there are other specification/details you want to provide, please let us know in the textbox below.</p>
    <v-textarea
      v-model="other"
      label="Other/Custom requirements"
      outlined
    />
    <center><v-btn
      color="primary"
      class="px-10"
      large
      to="confirmation"
      @click.native="scrollToTop()"
    >
      Submit
    </v-btn></center>
  </v-container>
</template>

<script>
import { briefingData } from '@/data/briefingData'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

export default ({
  components: {
    vueDropzone: vue2Dropzone
  },
  data() {
    return {
      briefing: briefingData,
      live_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      end_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      promo_start_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      promo_end_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      live_date_modal: false,
      live_time: '00:00',
      end_date_modal: false,
      end_time: '00:00',
      promo_start_date_modal: false,
      promo_start_time: '00:00',
      promo_end_date_modal: false,
      promo_end_time: '00:00',
      brands: '',
      budget: '',
      participating_venues: '',
      activity_name: '',
      activity_headline: '',
      privay_policy_url: '',
      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        thumbnailWidth: 150,
        maxFilesize: 0.5,
        headers: { 'My-Awesome-Header': 'header value' }
      },
      url_type_itmes: ['Standalone URL/Website', 'Existing URL/Website'],
      url: '',
      urgency: ['Not Urgent', 'Moderate', 'Urgent', 'Very Urgent']
    }
  }
})
</script>

<style lang="scss">
p {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.375rem;
  letter-spacing: 0.0071428571em;
  color: rgba(0, 0, 0, 0.6);
}
.vue-dropzone {
  border: 1px solid rgba(0,0,0,0.38);
  border-radius: 4px;
  &:hover {
    border-color: rgba(0,0,0,1);
  }
  &:focus, &:active {
    border-width: 2px;
    border-color: #EC088C;
  }
}
.dropzone .dz-message {
  margin: 1.25rem 0;
}
</style>
